
.slider-image {
	
   
    width: 100%; /* Set the width to 100% of the container */
    height: 10%; /* Maintain the aspect ratio */
}

.slick-prev, .slick-next {
    width: 100px;
    height: 100px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(12, 11, 16, 0.914); 
    border-radius: 50%; /* Make it circular */
    /* color: white; Set arrow color */
}

.slick-prev::before, .slick-next::before {
    font-size: 10px;
} 

/* Footer css */

.footer p{
  color: black;
  font-size: 17px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color:#112e42;
  transition: background-color 0.3s;
}

.social-icon:hover {
  background-color: rgba(255, 0, 0, 0.738); /* Change this to any color you prefer */
}

.social-icon i {
  color: #fff;
  transition: color 0.3s;
}

.social-icon:hover i {
  color: #fff;
}

.text-center {
  text-align: center;
}

 .text-white { 
  background-color:#112e42ae;
} 
.link-light {
  text-decoration: none; /* Ensure text-decoration is none */
}
/* end footer css------------ */



.container{
  
	position: relative;
	/* width:1100px; */
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 30px;
	margin-top: 2%;
}

.container .card{
 
	width:308px;
	position: relative;
	height: 400px;
	background:#112e42 ;
	margin:30px 10px;
	padding: 20px 15px;
	display:flex;
	flex-direction: column;
	box-shadow: 0.5px 10px #e6e6e6;
	transition:0.3s ease-in-out;
	margin-top: 5%;
}
 /* .container:hover{
  background-color: #333;
 }   */

.container .card .imgBx{
	color: white;
	position: relative;
	width:260px;
	height: 260px;
	top: -60px;
	left:20px;
	box-shadow: 0 5px 20px rbga (0,0,3,5.2);

}

.container .card .imgBx img
{
	max-width: 100%;
	border-radius: 10px;
}

.imgBx:hover img{
	transform: scale(1.1);
} 



/* services */
.card-body {
	color: white;
}
.card-text {
color: white;
}
.card-title{
	color: white;
}
.fw-bold{
	background-color: white;
}
.mt-5{
	background-color:burlywood;
}







/* Home */
#home{
    background: rgb(0, 101, 219);
    background: linear-gradient(90deg, rgb(0,101,219) 50%, rgba(0, 212, 255, 1) 100%);
    min-height: 500px;
}

/* Cards */
.card{
    transition: all 0.5s ease;
}
.card:hover{
    cursor: pointer;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

/* Form */
.form{
    background: rgb(0, 101, 219);
    background: linear-gradient(90deg, rgb(0,101,219) 30%, rgba(0, 212, 255, 1) 100%);
}







/* udated css */

.home {
	margin: 20px auto;
	max-width: 800px;
  }
  
  .slider-image {
	width: 100%;
	height: 400px; /* Adjust the height as needed */
	object-fit: cover; /* Ensures the image covers the entire area while maintaining its aspect ratio */
	border-radius: 8px;
	transition: opacity 3s ease-in-out;
  }
  
  .slick-dots li button:before {
	color: #333;
  }
  
  .slick-prev:before, .slick-next:before {
	color: #333;
  }
  
  .slide {
	position: relative;
  }
  
  .text-overlay {
	position: absolute;
	bottom: 20px; /* Adjust the position as needed */
	left: 20px; /* Adjust the position as needed */
	color: #085db3;

	
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 2rem;
  font-weight: 700;
  }
 
  
  /* Custom Arrow Styles */
  .slick-arrow {
	z-index: 1;
  }
  
  .slick-prev {
	left: 10px;
  }
  
  .slick-next {
	right: 10px;
  }
  
  .slick-prev:before, .slick-next:before {
	font-size: 30px;
	color: black; /* Change color as needed */
  }

  .team {
   
    text-align: center;
    padding: 50px 20px;
}

.team h1 {
    margin-bottom: 40px;
    font-size: 2.5em;
    color: #333;
}

.team-member {
    display: inline-block;
    width: 300px;
    margin: 10px;
    background: #fff;
    padding: 20px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    border-radius: 10px;
}

.team-member img {
    width: 70%;
    height: 70%;
    border-radius: 50%;
}

.team-member h2 {
    margin: 20px 0 10px;
    font-size: 1.5em;
    color: #333;
}

.team-member p {
  
    font-size: 1em;
    color:#333;
}


/* courses */


.courses {
  background-color: #112e42;
    text-align: center;
    padding: 50px 20px;
    
}

.courses h1 {
    margin-bottom: 40px;
    font-size: 2.5em;
    color:yellowgreen;
    
}

.course {
    display: inline-block;
    width: 300px;
    margin: 20px;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.course img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.course h2 {
    margin: 20px 0 10px;
    font-size: 1.5em;
    color: #333;
}

.course p {
    font-size: 1em;
    color: #666;
}



/* animation */
.home {
  margin-top: 0;
  position: relative;
  height: 100vh;
max-width:99%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  /* background: rgba(0, 0, 0, 0.5); */
  padding: 40px;
  
  text-align: center;
  border-radius: 10px;
}

.overlay h1 {
  color: #fff;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.animate-text {
  color: #fff;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


/* About css */

.col-md-6 img{
	box-shadow: 0 2px 25px 0 white;
   border-radius: 26px; 
}
.col-md-6 p{
  font-family: 'Nunito', sans-serif;
}
.col-md-6 img:hover{
   transform: perspective(2000px) rotatex(0deg) translatez(400px);
   transition: .1ms;
}

.about {
  padding: 20px;
  background-color: #f9f9f9;
}

.about h2 {
  
  text-align: center;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.student-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.student-container {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 20px;
  width: calc(33.333% - 20px); /* Three columns layout */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.student-container h3 {
  margin-top: 10px;
}

.student-container p {
  margin: 4px 0;
  font-family: 'Segoe UI', Tahoma;
  font-size: 17px;
}

.student-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.show-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px;
}

.show-button:hover {
  background-color: #0056b3;
}


/* <----media query----> */

/* Responsive Styles */
@media (max-width: 1200px) {
  .card, .course, .team-member {
    flex: 1 1 calc(50% - 40px);
  }
  .student-container {
    flex: 1 1 calc(50% - 20px);
}

}

@media (max-width: 768px) {
  .card, .course, .team-member {
    flex: 1 1 100%;

  }
  .student-container {
    flex: 1 1 100%;
}
  
  .text-overlay {
    font-size: 26.5px;
    padding: 5px 10px;
  }
}

@media (max-width: 480px) {
  .text-overlay {
    font-size: 20px;
    padding: 5px 8px;
  }

  .container, .courses, .team {
    padding: 10px;
  }
  .search-input {
    font-size: 14px;
    padding: 8px;
}

.student-container {
    padding: 5px;
}
}
/* <--------------------------------contact.css-------------> */
/* Contact Section */
.contactus-section {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.contactus-section .main-heading {
  font-size: 2.5rem;
  color: #343a40;
}

.contactus-section .main-hero-para {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 30px;
}

.contactus-section figure {
  margin-top: 30px;
}

.contactus-section img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Contact Form */
.contact-rightside {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.contact-input-feild {
  margin-bottom: 20px;
}

.contact-input-feild input[type='text'],
.contact-input-feild input[type='email'],
.contact-input-feild textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 1rem;
}

.contact-input-feild input[type='text']:focus,
.contact-input-feild input[type='email']:focus,
.contact-input-feild textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
}

.form-check {
  margin-bottom: 20px;
}

.form-check .form-check-input {
  margin-right: 10px;
  color: #0a48c3ee;
}

.btn-style {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 15px;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
}

.btn-style:hover {
  background-color: #b300a4;
}

@media (max-width: 768px) {
  .contact-leftside {
    text-align: center;
    margin-bottom: 50px;
  }

  .contact-rightside {
    padding: 20px;
  }
}


/* <--------------number animation------> */




h1 {
  font-weight: 1200;
  font-size: 3rem;
  margin-bottom: 1rem;
  font-family: 'Arial', sans-serif;
}

.animate-text p {
  color: #112e42;
  font-size: 1.5rem;
  margin: 0.5rem 0;
  font-family: 'Arial', sans-serif;
  animation: fadeIn 1s ease-in-out;
}

/* <--------------> */
