.courses-container {
    position: relative;
    width: 100%;
    height: 400px; /* Adjust the height as needed */
    overflow: hidden;
}

.courses-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire container */
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

.text-overlay h2 {
    font-size: 36px;
    margin-bottom: 10px;
}

.text-overlay p {
    font-size: 18px;
    max-width: 80%;
    margin: 0 auto;
}




.courses {
    background: #112e42;
      text-align: center;
      padding: 50px 20px;
  }
  
  .courses h1 {
      margin-bottom: 40px;
      font-size: 2.5em;
      color: greenyellow;
  }
  
  .course {
      display: inline-block;
      width: 300px;
      margin: 20px;
      background: #fff;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
  }
  
  .course img {
      width: 100%;
      height: auto;
      border-radius: 10px;
  }
  
  .course h2 {
      margin: 20px 0 10px;
      font-size: 1.5em;
      color: #333;
  }
  
  .course p {
      font-size: 1em;
      color: #666;
  }
  