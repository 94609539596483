.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .link {
    padding: 10px;
    text-decoration: none;
    color: black;
  }
  

.contact-header {

    background-color:#081b29f0;
    color: #fff;
    padding: 2px;
    text-align: center;
}

.contact-details {
    font-size: 1.2em;
    margin-top: 5px;
    margin-bottom: 0;
}

.contact-number, .contact-email {
    font-weight: bold;
}

.contact-email {
    color: #ffcc00;
    text-decoration: none;
}

.contact-email:hover {
    text-decoration: underline;
}


.justify-content-space-between{
    justify-content: space-between;
}

.plr-10{
    padding-left: 7%;
    padding-right: 3%;
}
.ptb-20{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
}
.pl-5{
    padding-left: 20px;
}
.nav-links{
    color:  #71BCE1;
    cursor: pointer;
    
}
.nav-links :hover{
    color:#08a7f6;
}
.link{
    color:  #71BCE1;
    text-decoration: none;
}
.alt-1{
    font-size: 30px;
    font-weight: 1000;
    place-items: center;
    color:  #71BCE1;
    
}

.logo{
   
    border-radius:50%  ;
}

/* updated css */
body {
    font-family: "Helvetica Neue", Arial, "Liberation Sans", sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.contact-header {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
}

.contact-details {
    font-size: 1em;
    margin: 0;
}

.contact-number, .contact-email {
    font-weight: bold;
}

.contact-email {
    color: #ffcc00;
    text-decoration: none;
}

.contact-email:hover {
    text-decoration: underline;
}

.navbar {
    
    width: 100%;
    background-color: #fff;
    transition: all 0.3s ease;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.navbar.scrolled {
    position: fixed;
    top: 0;
    left: 0;
  
    width: 100%;
    background-color: #fff;
    padding: 20px 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.nav-logo {
    display: flex;
    align-items: center;
}

.nav-logo .logo {
    height: 50px;
    margin-right: 10px;
}

.alt-1 {
    font-size: 1.5em;
    font-weight: bold;
    color: black;
}

.flex {
    display: flex;
    align-items: center;
}

.nav-links {
    padding-left: 20px;
}

.link {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

.link:hover {
    color: #ffcc00;
}

/* media query */


.burger {
    display: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.199);
    backdrop-filter: blur(20px);
    transition: .3s;
    /* border: 2px solid black; */
    /* transition: background-color 0.3s ease, border-color 0.3s ease; */
  }
  
  .burger i {
    font-size: 1.7rem;
    color:black;
  }

 
  
  .burger:hover i {
    color:rgba(1, 8, 14, 0.919);
  }
  
  .dropdown-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 20px;
    background-color: #333;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .dropdown-menu.open {
    display: flex;
  }
  
  .dropdown-menu .link {
    text-align: center;
    padding: 10px 0;
    color: black;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .dropdown-menu .link:hover {
    color: #007BFF;
  }
  

 

  @media (max-width: 768px) {
    .nav-links {
      display: none;
    }
  
    .burger {
      display: block;
    }
   
  }












  