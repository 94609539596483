.services-container {
    display: flex;
    justify-content: space-around;
    margin: 20px auto;
    /* max-width: 1000px; */
}

.services-section {
    /* width: 45%; */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
}
.sub-container{
    background-color: #333;
}

.download-buttons {
    margin-bottom: 20px;
}

.download-button {
    display: block;
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.download-button:hover {
    background-color: #45a049;
}

.show-more-button {
    background-color: #008CBA;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.show-more-button:hover {
    background-color: #005f6b;
}

.additional-content {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
}

/* <---------media query------------> */

@media (max-width: 768px) {
   .services-container{
    flex-wrap: wrap;
    flex-direction: column;
   
   }
    .services-section {
       
        padding: 20px;
    }
  
    .download-buttons {
        
      flex-direction: column;
    }
  }